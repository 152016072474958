import React, { useState } from 'react';
import './Navbar.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);  // State for mobile menu toggle

  const handleScroll = (e, id) => {
    e.preventDefault();
    const target = document.querySelector(id);

    if (target) {
    //  const navbarHeight = document.querySelector('.navbar').offsetHeight;
      

      // Smooth scroll to target using scrollIntoView
      target.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
      });

      // Offset adjustment (for fixed navbar)
      window.scrollTo({
        top: 300,
        behavior: 'smooth'
    });

      setIsOpen(false);  // Close the menu after click (optional for mobile)
    } else {
      console.error("Section not found: ", id);
    }
};


  return (
    <nav className={`navbar ${isOpen ? 'open' : ''}`}>
      <div className="navbar-logo">Kunal Kapoor</div>
      <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
        <i className={`fas ${isOpen ? 'fa-times' : 'fa-bars'}`}></i>
      </div>

      <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <li>
          <a href="#about-section" onClick={(e) => handleScroll(e, '#about-section')}>
            About
          </a>
        </li>
        <li>
          <a href="#experience" onClick={(e) => handleScroll(e, '#experience')}>
            Experience
          </a>
        </li>
        <li>
          <a href="#projects" onClick={(e) => handleScroll(e, '#projects')}>
            Projects
          </a>
        </li>
        <li>
          <a href="#skills" onClick={(e) => handleScroll(e, '#skills')}>
            Skills
          </a>
        </li>
        <li>
          <a href="#education" onClick={(e) => handleScroll(e, '#education')}>
            Education
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
