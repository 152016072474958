import AWS from 'aws-sdk';
import { getBestMatch } from '../utils/fuzzyMatch';

// Configure AWS SDK
AWS.config.update({
  region: 'us-east-1',  // Adjust the region as necessary
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();

export const loadAllQuestions = async () => {
    console.log("loadAllQuestions function called");
    const params = {
        TableName: 'ChatbotIntents',
        ProjectionExpression: 'question' // Fetch only the `question` attribute
    };

    try {
        const data = await dynamoDB.scan(params).promise();
        const questions = data.Items.map(item => item.question); // Extract questions
        console.log("Questions loaded from DynamoDB:", questions); // Log all questions in lowercase
        return questions;
    } catch (error) {
        console.error('Error loading questions from DynamoDB:', error);
        return []; // Return an empty array in case of error
    }
};

export const getChatbotIntent = async (userQuestion, storedQuestions) => {
const matchedQuestion = getBestMatch(userQuestion, storedQuestions);
if (matchedQuestion) {
const params = {
    TableName: 'ChatbotIntents', // Your DynamoDB table name
    IndexName: 'question-index',  // Use the correct index name for `question`
    KeyConditionExpression: '#question = :question', // Use the alias for `question`
    ExpressionAttributeNames: { '#question': 'question' },  // Define the alias for `question`
    ExpressionAttributeValues: { ':question': matchedQuestion } // Set the value for userQuestion
  };

  try {
    const data = await dynamoDB.query(params).promise(); // Perform query on the index
    return data.Items && data.Items.length > 0 
      ? data.Items[0].answer 
      : 'Hold tight! 🚧 Kunal’s still fine-tuning me.🔧';
  } catch (error) {
    console.error('Error fetching data from DynamoDB:', error);
    return 'Error connecting to the chatbot.';
  }
}
};
