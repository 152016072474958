// src/utils/fuzzyMatch.js
function levenshteinDistance(str1, str2) {
    const len1 = str1.length;
    const len2 = str2.length;
    const dp = Array.from(Array(len1 + 1), () => Array(len2 + 1).fill(0));

    for (let i = 0; i <= len1; i++) dp[i][0] = i;
    for (let j = 0; j <= len2; j++) dp[0][j] = j;

    for (let i = 1; i <= len1; i++) {
        for (let j = 1; j <= len2; j++) {
            if (str1[i - 1] === str2[j - 1]) {
                dp[i][j] = dp[i - 1][j - 1];
            } else {
                dp[i][j] = Math.min(
                    dp[i - 1][j - 1] + 1, // substitution
                    dp[i][j - 1] + 1,     // insertion
                    dp[i - 1][j] + 1      // deletion
                );
            }
        }
    }
    return dp[len1][len2];
}

export function getBestMatch(userQuery, storedQuestions, threshold = 11) {
    console.log("Starting fuzzy matching...");
    console.log("User query:", userQuery);
    console.log("Stored questions:", storedQuestions);

    let closestMatch = null;
    let minDistance = Infinity;

    storedQuestions.forEach(question => {
        const distance = levenshteinDistance(userQuery.toLowerCase(), question.toLowerCase());
        console.log(`Comparing "${userQuery}" with "${question}" - Distance: ${distance}`);

        if (distance < minDistance && distance <= threshold) {
            minDistance = distance;
            closestMatch = question;
            console.log(`New closest match: "${closestMatch}" with distance: ${minDistance}`);
        }
    });

    console.log("Fuzzy match result:", closestMatch);
    return closestMatch;
}
